import React from 'react';
import { useState, useEffect } from 'react';
import "../css/threatcart.css"
import * as FaIcons from 'react-icons/fa';
import moment from 'moment';
import LargeSpinner from '../components/LargeSpinner';
import { domainHook } from '../hooks/domain';
import { useUser } from '../hooks/useUser';

export interface ChangeLog {
    [key: string]: string;
    userID: string;
    changeDate: string;
    changeItem: string;
    changeText: string;
}

const User: React.FunctionComponent = () => {
    const {userObject} = useUser();
    const [changeLogObject, setChangeLogObject] = useState<ChangeLog[]>([]);
    const [loading, setLoading] = useState(false);

    let domain = domainHook();

    const getUserVendorCves = async(userID: string) => {
        setLoading(true);
            fetch(`${domain}/ChangeLog/GetChangeLogByUser?userID=${userID}` ,{method: 'GET',})
                .then((response) => response.json())
                .then((data) => {
                    setChangeLogObject(data);
                    setLoading(false);
            }).catch((err) => {
                console.log(err);
            })
            .finally(() => {
                //setLoadingUser(false);
            });
    }

    const updateUser = async() => {
        if(userObject != null){
            const date = new Date();
            userObject.lastLogin = moment(date).format("YYYY-MM-DDTHH:mm:ss");

            fetch(`${domain}/User/UpdateLastLogin?userID=${userObject.userID}&userData=${JSON.stringify(userObject)}` ,{method: 'PUT',})
        .then((response) => response.json())
        .then((data) => {
            //setLoading(false);
        }).catch((err) => {
            console.log(err);
        })
        .finally(() => {
            //setLoadingUser(false);
        });
        }
    }

    useEffect(() => {
        getUserVendorCves(userObject?.userID!);
        updateUser();
    },[]);

    return (
        <>
            <div id="vendors" className="tabcontent">
            <h3>User Profile</h3>

            <div className="dashboard-container">

            <div style={{width: "100%", height: "200px"}}>
                <div style={{width: "10%", height: "120px", float: "left", marginRight: "10px"}}>
                    <div style={{width: "100%", height: "120px", textAlign: "center", border: "1px solid"}}>
                        <i className="fas fa-shield-alt" style={{fontSize:'70px', color: "#0dcaf0"}}><FaIcons.FaUser /></i>
                    </div>
                    <div style={{width: "100%", height: "120px", float: "left", textAlign: "center"}}>
                        <div>{userObject?.firstName} {userObject?.lastName}</div>
                        <div>ID: {userObject?.userID}</div>
                    </div>
                </div>
                <div style={{width: "50%", height: "120px", marginLeft: "20px"}}>
                    <div style={{float: "left", marginRight: "4px"}}>Email: </div><div style={{fontWeight: "bold"}}>{userObject?.email}</div>
                    <div style={{float: "left", marginRight: "4px"}}>Password: </div><div style={{fontWeight: "bold"}}>*********</div>
                    <div style={{float: "left", marginRight: "4px"}}>Created Date: </div><div style={{fontWeight: "bold"}}>{moment(userObject?.createdDate).format("YYYY MMM DD h:mm A")}</div>
                    <div style={{float: "left", marginRight: "4px"}}>Account Expiry: </div><div style={{fontWeight: "bold"}}>{moment(userObject?.loginExpiry).format("YYYY MMM DD h:mm A")}</div>
                    <div style={{float: "left", marginRight: "4px"}}>Last Login: </div><div style={{fontWeight: "bold"}}>{moment(userObject?.lastLogin).format("YYYY MMM DD h:mm A")}</div>
                </div>
            </div>

            <div style={{width: "100%", backgroundColor: "#F5F5F5", padding: "10px"}}>
                <h5 style={{padding: "10px"}}>User Change Log:</h5>
                <table style={{width:"100%", border: "1px solid"}}>
                    <thead style={{borderBottom: "2px solid"}}>
                        <th>Change Date</th>
                        <th>Change Item</th>
                        <th>Change Details</th>
                    </thead>
                    <tbody>
                    {loading ? <div style={{width:"100%", height: "390px", backgroundColor: "lightgray", position: "absolute", zIndex: "20", opacity: "0.7" }}><LargeSpinner /></div>: ""}
                    {changeLogObject === undefined || changeLogObject.length == 0 ? <tr><td colSpan={3} style={{textAlign:"center"}}>No Changes</td></tr> : changeLogObject?.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td>{moment(item.changeDate).format("MMM DD 'YY   h:mma")}</td>
                                <td>{item.changeItem}</td>
                                <td>{item.changeText}</td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            </div>
        </div>
        </div>
        </>
    )
}

export default User
