import React from 'react'
import { useState, useEffect } from 'react'
import styled from 'styled-components'
import Button from 'react-bootstrap/Button'
import 'bootstrap/dist/css/bootstrap.min.css';
import "../css/threatcart.css"
import SpinnerLoader from '../components/SpinnerLoader'
import Modal from 'react-bootstrap/Modal';
import * as FaIcons from 'react-icons/fa';
import { domainHook } from '../hooks/domain';
import { useUser } from '../hooks/useUser';

export interface VendorObject {
    userID: string;
    vendorProject: string;
}

const Vendor: React.FunctionComponent = () => {
    const {userObject} = useUser();
    const [vendorObject, setVendorObject] = useState<VendorObject[]>([]);
    const [vendorUserObject, setVendorUserObject] = useState<VendorObject[]>([]);
    const [loadingUser, setLoadingUser] = useState(false);
    const [loadingVendor, setLoadingVendor] = useState(false);
    const [deleteVendor, setDeleteVendor] = useState("");

    const [show, setShow] = useState(false);
    const handleClose = () => {setShow(false); setDeleteVendor("")};
    const handleShow = () => setShow(true);

    let domain = domainHook();

    const getVendors = async(userVendors: VendorObject[]) => {
        setLoadingVendor(true);
            fetch(`${domain}/Vendor/GetVendorsAll` ,{method: 'GET',})
              .then((response) => response.json())
              .then((data: VendorObject[]) => {
                const newArray = data.filter(({ vendorProject }) => !userVendors.some((e) => e.vendorProject === vendorProject))
                setVendorObject(newArray);
            }).catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoadingVendor(false);
            });
    }

    const getUserVendors = async(userID: string) => {
        setLoadingUser(true);
            fetch(`${domain}/Vendor/GetVendorsByUser?userID=${userID}` ,{method: 'GET',})
              .then((response) => response.json())
              .then((data) => {
                setVendorUserObject(data);
                getVendors(data);
            }).catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoadingUser(false);
            });
    }

    const insertLog = async(logItem: string, logText : string) => {

        const date = new Date();
        date.setHours(date.getHours() - 4);
        var jsonObject = { userID : userObject?.userID ,changeItem : logItem, changeText : logText, changeDate: date};

        console.log(jsonObject);

        let updateObjectJson = JSON.parse(JSON.stringify(jsonObject));

        fetch(`${domain}/ChangeLog/InsertChangeLog?changeLogData=${JSON.stringify(updateObjectJson)}` ,{method: 'PUT',})
        .then((response) => response.json())
        .then((data) => {
            //setLoading(false);
        }).catch((err) => {
            console.log(err);
        })
        .finally(() => {
            //setLoadingUser(false);
        });
    }

    const insertVendorUser = async(vendorName: string) => {
        fetch(`${domain}/Vendor/InsertVendorsUser?userID=${userObject?.userID}&vendorName=${vendorName}` ,{method: 'POST',})
        .then((response) => response.json())
        .then((data) => {
            getUserVendors(userObject?.userID == null ? "" : userObject?.userID);
        }).catch((err) => {
          console.log(err);
        })
        .finally(() => {
            setLoadingUser(false);
        });
        insertLog("Vendor Added", `${vendorName}`);
    }

    const deleteVendorUser = async(vendorName: string) => {
        fetch(`${domain}/Vendor/DeleteVendorsUser?userID=${userObject?.userID}&vendorName=${vendorName}` ,{method: 'DELETE',})
        .then((response) => response.json())
        .then((data) => {
            getUserVendors(userObject?.userID == null ? "" : userObject?.userID);
      }).catch((err) => {
          console.log(err);
      })
      .finally(() => {
          setLoadingUser(false);
      });
      insertLog("Vendor Removed", `${vendorName}`);
    }

    const removeVendor = async(vendorName : string) => {
        setDeleteVendor(vendorName);
        handleShow();
    }

    useEffect(() => {
        getUserVendors(userObject?.userID!);
    },[]);

    return (
        <>      
        <div id="vendors" className="tabcontent">
                <h3>Vendor Admin</h3>
                <p>Your Vendors</p>
                <div id="userVendors" className="userVendors">
                {loadingUser ? <SpinnerLoader /> : ""}
                {vendorUserObject == null ? "" : vendorUserObject?.map((vendor, index) => {
                    return (
                    <Button key={index} className="btn btn-success btn-outline-success vendorButton" onClick={()=> {removeVendor(vendor.vendorProject);}}>{vendor.vendorProject}</Button>
                    /*<Button key={index} className="btn btn-success btn-outline-success vendorButton" onClick={()=> {deleteVendorUser(vendor.vendorProject);}}>{vendor.vendorProject}</Button>*/
                    );
                })}
                </div>
                <br />
                <br />
                <br />
                <p style={{color:'black', borderTop: '1px solid #e0e0e0'}}>Vendor List</p>
                <br />
                <div id="vendorContainer" className="vendorContainer">
                {loadingVendor ? <SpinnerLoader /> : ""}
                {vendorObject == null ? "" : vendorObject?.map((vendor, index) => {
                    return (
                    <Button key={index} variant="outline-info" className="vendorButtonAdd" onClick={()=> {insertVendorUser(vendor.vendorProject);}}>{vendor.vendorProject}</Button>
                    );
                })}

                </div>
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                    <i className="fas fa-shield-alt" style={{marginRight: "5px"}}><FaIcons.FaTrash /></i>Remove Vendor
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to remove Vendor <b>{deleteVendor}</b> from your list?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        No
                    </Button>
                    <Button variant="primary" onClick={()=> {deleteVendorUser(deleteVendor);handleClose();}}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>

        </>

    )
}

export default Vendor
