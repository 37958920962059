import React, { useCallback, useEffect, useState } from 'react'
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as FaIcons from 'react-icons/fa';
import LargeSpinner from '../components/LargeSpinner'
import CveProtectionDetails from '../components/CveProtectionDetails'
import { domainHook } from '../hooks/domain';
import { useUser } from '../hooks/useUser';

export interface VendorObject {
    userID: string;
    vendorProject: string;
}

export interface CveDetailsObject {
    cveID: string;
    vulnerabilityName: string;
    vendorProduct: string;
    product: string;
    dateAdded: string;
    dueDate: string;
    requiredAction: string;
    shortDescription: string;
}

export interface UserCvesObject {
    //[key: string]: string;
    userID: string;
    cveID: string;
    vendorProduct: string;
    cveWorked?: string;
    networkFirewallStatus: string;
    networkFirewallComment: string;
    networkIDSIPSStatus: string;
    networkIDSIPSComment: string;
    networkWebStatus: string;
    networkWebComment: string;
    networkEmailStatus: string;
    networkEmailComment: string;
    endpointEDRStatus: string;
    endpointEDRComment: string;
    endpointIPSStatus: string;
    endpointIPSComment: string;
    endpointFirewallStatus: string;
    endpointFirewallComment: string;
    endpointWebStatus: string;
    endpointWebComment: string;
    appSIEMStatus: string;
    appSIEMComment: string;
    appEDRStatus: string;
    appEDRComment: string;
    appMitigationConfigStatus: string;
    appMitigationConfigComment: string;
    appMitigationPatchingStatus: string;
    appMitigationPatchingComment: string;
}

export const cveOptionTypesValues = ['networkFirewallStatus','networkIDSIPSStatus', 'networkWebStatus', 'networkEmailStatus',
    'endpointEDRStatus','endpointIPSStatus', 'endpointFirewallStatus', 'endpointWebStatus',
    'appSIEMStatus','appEDRStatus', 'appMitigationConfigStatus', 'appMitigationPatchingStatus'];

const Cve: React.FunctionComponent = () => {
    const {userObject} = useUser();
    const [selectedCveDetailObject, setSelectedCveDetailObject] = useState<CveDetailsObject[]>([]);
    const [userVendorCvesObject, setUserVendorCvesObject] = useState<UserCvesObject[]>([]);

    const [networkPercentage, setNetworkPercentage] = useState(0); 
    const [endpointPercentage, setEndpointPercentage] = useState(0); 
    const [appPercentage, setAppPercentage] = useState(0); 
    const [totalPercentage, setTotalPercentage] = useState(0); 

    const [selectCveValue, setSelectCveValue] = useState("");
    const [selectVendorValue, setSelectVendorValue] = useState("");
    const [loading, setLoading] = useState(false);

    let domain = domainHook();

    const getUserVendorCves = async(userID: string) => {
        //setLoadingUser(true);
            fetch(`${domain}/Cve/GetVulnerabilitiesByUserId?userID=${userID}` ,{method: 'GET',})
                .then((response) => response.json())
                .then((data) => {
                    setUserVendorCvesObject(data);
                    calculatePercentage(data);
            }).catch((err) => {
                console.log(err);
            })
            .finally(() => {
                //setLoadingUser(false);
            });
    }

    const getSelectedVendor = async(vendorName: string) => {
        setSelectCveValue("");
        setSelectVendorValue(vendorName);

        //setLoadingUser(true);
            fetch(`${domain}/Vulnerabilities/GetVulnerabilitiesByVendor?vendorName=${vendorName}` ,{method: 'GET',})
              .then((response) => response.json())
              .then((data) => {
                setSelectedCveDetailObject(data);
            }).catch((err) => {
                console.log(err);
            })
            .finally(() => {
                //setLoadingUser(false);
            });
    }

    const getSelectedCVE = async(cveID: string) => {
        setSelectCveValue(cveID);
        //let cveObject = selectedCveDetailObject.find(x => x.cveID === cveID);
        //setSelectedCveObject(cveObject);
    }

    const formatText = (protectionLevel: string) =>{
        if (protectionLevel == "advancedprotection") {
            return "Advanced Protection";
        } else if (protectionLevel == "moderateprotection") {
            return "Moderate Protection";
        } else if (protectionLevel == "noprotection") {
            return "No Protection";
        } else {
            return "Does Not Apply";
        }
    }

    function cssColor(cveItem : string){
        if (cveItem == "advancedprotection") {
            return "green";
        } else if (cveItem == "moderateprotection") {
            return "yellow";
        } else if (cveItem == "noprotection") {
            return "pink";
        } else {
            return "grey";
        }
    }

    const insertLog = async(logItem: string, logText : string) => {

        const date = new Date();
        date.setHours(date.getHours() - 4);
        var jsonObject = { userID : userObject?.userID ,changeItem : logItem, changeText : logText, changeDate: date};
        let updateObjectJson = JSON.parse(JSON.stringify(jsonObject));

        fetch(`${domain}/ChangeLog/InsertChangeLog?changeLogData=${JSON.stringify(updateObjectJson)}` ,{method: 'PUT',})
        .then((response) => response.json())
        .then((data) => {
            //setLoading(false);
        }).catch((err) => {
            console.log(err);
        })
        .finally(() => {
            //setLoadingUser(false);
        });
    }

    const updateCVELevel = async(protectionItem: string, protectionLevel : any, previousItem: string) => {
        setUserVendorCvesObject([]);

        userVendorCvesObject.filter(function(item) { return item.cveID === selectCveValue }).map((cveItem) => (
            Object.keys(cveItem).forEach((item) => {
                if(item === protectionItem) {
                    cveItem[item as keyof UserCvesObject] = protectionLevel;
                    cveItem["cveWorked"] = "true";
                }
            }
        )));

        let updatedObject = userVendorCvesObject.filter(function(item) { return item.cveID === selectCveValue });

        updatedObject.forEach(a => {
            delete a.cveWorked;
        });

        let updateObjectJson = JSON.parse(JSON.stringify(updatedObject[0]));
        updateObjectJson["userID"] = userObject?.userID;

        fetch(`${domain}/Cve/UpdateCVEProtection?userID=${userObject?.userID}&cveData=${JSON.stringify(updateObjectJson)}` ,{method: 'PUT',})
        .then((response) => response.json())
        .then((data) => {
            setLoading(false);
        }).catch((err) => {
            console.log(err);
        })
        .finally(() => {
            //setLoadingUser(false);
        });

        insertLog("CVE Protection Change",`CVE: ${selectCveValue} | Item: ${protectionItem} = Previous Level: ${formatText(previousItem)} | New Level: ${formatText(protectionLevel)}`);

        setTimeout(() => { setUserVendorCvesObject(userVendorCvesObject); calculatePercentage(userVendorCvesObject);}, 200);

        //setInterval(() => {setUserVendorCvesObject(userVendorCvesObject);calculatePercentage(userVendorCvesObject);}, 200);
    }

    function cveKeyMapping(key : string, cveItem: any){
        if(key === "networkFirewallStatus"){
            return cveItem.networkFirewallStatus;
        } else if (key === "networkIDSIPSStatus"){
            return cveItem.networkIDSIPSStatus;
        } else if (key === "networkWebStatus"){
            return cveItem.networkWebStatus;
        } else if (key === "networkEmailStatus"){
            return (cveItem.networkEmailStatus);
        } else if (key === "endpointEDRStatus"){
            return (cveItem.endpointEDRStatus);
        } else if (key === "endpointIPSStatus"){
            return (cveItem.endpointIPSStatus);
        } else if (key === "endpointFirewallStatus"){
            return (cveItem.endpointFirewallStatus);
        } else if (key === "endpointWebStatus"){
            return (cveItem.endpointWebStatus);
        } else if (key === "appSIEMStatus"){
            return (cveItem.appSIEMStatus);
        } else if (key === "appEDRStatus"){
            return (cveItem.appEDRStatus);
        } else if (key === "appMitigationConfigStatus"){
            return (cveItem.appMitigationConfigStatus);
        } else if (key === "appMitigationPatchingStatus"){
            return (cveItem.appMitigationPatchingStatus);
        } 
    }

    function calculatePercentage(ucveObject: UserCvesObject[]) {
        let networkTotal = 0;
        let endPointTotal = 0;
        let appTotal = 0;
        let systemTotal = 0;

        if(ucveObject.length > 0) {
            ucveObject!.map((cve: any) => {
                networkTotal += getProtectionLevelToInt(cve.networkEmailStatus == null ? "" : "") + getProtectionLevelToInt(cve.networkFirewallStatus) + getProtectionLevelToInt(cve.networkIDSIPSStatus) + getProtectionLevelToInt(cve.networkWebStatus);
                endPointTotal += getProtectionLevelToInt(cve.endpointEDRStatus) + getProtectionLevelToInt(cve.endpointFirewallStatus) + getProtectionLevelToInt(cve.endpointIPSStatus) + getProtectionLevelToInt(cve.endpointWebStatus);
                appTotal += getProtectionLevelToInt(cve.appEDRStatus) + getProtectionLevelToInt(cve.appMitigationConfigStatus) + getProtectionLevelToInt(cve.appSIEMStatus) + getProtectionLevelToInt(cve.appMitigationPatchingStatus);
            })
            systemTotal = Math.round((networkTotal+endPointTotal+appTotal) / (ucveObject.length*1200)*100);
            networkTotal = Math.round(networkTotal / (ucveObject.length*400)*100);
            endPointTotal = Math.round(endPointTotal / (ucveObject.length*400)*100);
            appTotal = Math.round(appTotal / (ucveObject.length*400)*100);
        }

        setNetworkPercentage(networkTotal);
        setEndpointPercentage(endPointTotal);
        setAppPercentage(appTotal);
        setTotalPercentage(systemTotal);
    }

    function getProtectionLevelToInt(protectionLevel: string) {

        if (protectionLevel == "doesnotapply") {return 100;}
        else if (protectionLevel == "moderateprotection") {return 50;}
        else if (protectionLevel == "advancedprotection") {return 100;}
        else { return 0;}
    }

    useEffect(() => {
        getUserVendorCves(userObject?.userID!);
    },[]);

    return (

        <div id="dashboard" className="tabcontent">
            <h3>Dashboard CVE</h3>
            <p>The Dashboard For Vulnerabilities</p>
            <div className="dashboard-container">

                <div className="dheading-row">
                    <div className="dheading-column1">

                        <div style={{lineHeight: "30px",float: "left",width: "66px",textAlign: "center"}}>Vendors</div>
                        <DropdownButton size="sm" variant="light" className={"d-grid"} title={selectVendorValue == "" ? "- SELECT - " : selectVendorValue} style={{width: "39%",float: "left"}}>
                        {userVendorCvesObject == null ? "" : userVendorCvesObject.filter((v,i,a)=>a.findIndex(v2=>(v.vendorProduct === v2.vendorProduct))===i).map((vendor, index) => {
                            return (<Dropdown.Item key={index} onClick={() => getSelectedVendor(vendor.vendorProduct)}>{vendor.vendorProduct}</Dropdown.Item>);
                            })}
                        </DropdownButton>

                        <div style={{lineHeight: "30px",float: "left",width: "50px",textAlign: "center", marginLeft: '30px'}}>CVE's</div>
                        <DropdownButton size="sm" variant="light" className={"d-grid"} title={selectCveValue == "" ? "- SELECT - " : selectCveValue}>
                        {selectVendorValue == "" ? "" : userVendorCvesObject?.map((vendorCve, index) => {
                            return (
                                vendorCve.vendorProduct != selectVendorValue ? "" : vendorCve.cveWorked === "false" ? <Dropdown.Item key={index} onClick={() => getSelectedCVE(vendorCve.cveID)}>  {vendorCve.cveID}</Dropdown.Item> : <Dropdown.Item style={{color:'green'}} key={index} onClick={() => getSelectedCVE(vendorCve.cveID)}><FaIcons.FaCheck />  {vendorCve.cveID}</Dropdown.Item>
                                /*vendorCve.vendorProject === selectVendorValue ? <Dropdown.Item key={index} onClick={() => getSelectedCVE(vendorCve.cveID)}>  {vendorCve.cveID}</Dropdown.Item> :
                                ""*/
                            );
                            })}
                        </DropdownButton>
                    </div>

                    <div className="dheading-column2">
                        <div className="dheading-item">
                            <div className="selectionLabel">Vendor Project:&nbsp;</div>
                            <label className="selectionLabelValue" id="vendorProject">{selectedCveDetailObject.find(x => x.cveID === selectCveValue)?.vendorProduct}</label>
                            <br />
                            <div className="selectionLabel">Product:&nbsp;</div>
                            <label className="selectionLabelValue" id="product">{selectedCveDetailObject.find(x => x.cveID === selectCveValue)?.product}</label>
                            <br />
                            <div className="selectionLabel">Date Added:&nbsp;</div>
                            <label className="selectionLabelValue" id="dateAdded">{selectedCveDetailObject.find(x => x.cveID === selectCveValue)?.dateAdded}</label>
                        </div>
                    </div>

                    <div className="dheading-column2">
                        <div className="dheading-item">
                            <div className="selectionLabel">Due Date:&nbsp;</div>
                            <label className="selectionLabelValue" id="dueDate">{selectedCveDetailObject.find(x => x.cveID === selectCveValue)?.dueDate}</label>
                            <br />
                            <div className="selectionLabel">Required Action:&nbsp;</div>
                            <label className="selectionLabelValueTextMore" id="requiredAction">{selectedCveDetailObject.find(x => x.cveID === selectCveValue)?.requiredAction}</label>
                        </div>
                    </div>

                    <div className="dheading-column2">
                        <div className="dheading-item">
                            <div className="selectionLabel2">Vulnerability Name:&nbsp;</div>
                            <label className="selectionLabelValueTextMore" id="vulnerabilityName">{selectedCveDetailObject.find(x => x.cveID === selectCveValue)?.vulnerabilityName}</label>
                        </div>
                    </div>

                    <div className="dheading-column3">
                        <div className="dheading-item2">
                            <div className="selectionLabel2">Short Description:&nbsp;</div>
                            <label className="selectionLabelValueTextMore" id="shortDescription">{selectedCveDetailObject.find(x => x.cveID === selectCveValue)?.shortDescription}</label>
                        </div>
                    </div>
                </div>

                {!selectCveValue && <div className="dcve-row-nocve">
                    <div id="nodcve-text">No CVE Selected</div>
                </div>}

                {selectCveValue && 
                <div style={{display:''}} className="dcve-row">
                    <div className="drowtitle">CVE SETTINGS</div>

                    {loading ? <div style={{width:"100%", height: "390px", backgroundColor: "lightgray", position: "absolute", zIndex: "20", opacity: "0.7" }}><LargeSpinner /></div>: ""}
                    
                    {userVendorCvesObject.filter(function(item) { return item.cveID === selectCveValue }).map((cveItem, index) => (

                       cveOptionTypesValues.map((protectionItem) => (

                        <div key={index} className="dcve-column">
                            <div className="dcve-item">
                                <CveProtectionDetails item={protectionItem} cveObject={cveItem} />
                                <DropdownButton onSelect={function(evt){updateCVELevel(protectionItem, evt, cveKeyMapping(protectionItem, cveItem)); }} size="sm" variant="primary-outline" className={`d-grid card-footer cve-btn bg-c-${cssColor(cveKeyMapping(protectionItem, cveItem))}`} title={formatText(cveKeyMapping(protectionItem, cveItem))}>
                                    <Dropdown.Item eventKey='doesnotapply'>Does Not Apply</Dropdown.Item>
                                    <Dropdown.Item eventKey='noprotection'>No Protection</Dropdown.Item>
                                    <Dropdown.Item eventKey='moderateprotection'>Moderate Protection</Dropdown.Item>
                                    <Dropdown.Item eventKey='advancedprotection'>Advanced Protection</Dropdown.Item>
                                </DropdownButton>
                            </div>
                        </div>
                       ))
                    ))}
                    
                </div>}

                <div className="dall-row">
                    <div className="drowtitle">YOUR SYSTEM STATISTICS</div>
                    <div className="dcve-column">
                        <div className="dcve-item statitem">
                            <div className="statheader">OVERALL</div>
                            <span style={{display:'inline-block'}} className="statvalue" id="overallPrctg">{totalPercentage} %</span>
                        </div>
                    </div>
                    <div className="dcve-column">
                        <div className="dcve-item statitem">
                            <div className="statheader">NETWORK</div>
                            <span style={{display:'inline-block'}}  className="statvalue" id="networkPrctg">{networkPercentage} %</span>
                        </div>
                    </div>
                    <div className="dcve-column">
                        <div className="dcve-item statitem">
                            <div className="statheader">END POINT</div>
                            <span style={{display:'inline-block'}}  className="statvalue" id="endpointPrctg">{endpointPercentage} %</span>
                        </div>
                    </div>
                    <div className="dcve-column">
                        <div className="dcve-item statitem">
                            <div className="statheader">APP</div>
                            <span style={{display:'inline-block'}}  className="statvalue" id="appPrctg">{appPercentage} %</span>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    )
}

export default Cve
