import React from 'react';
import { useState, useEffect } from 'react';
import "../css/threatcart.css"
import * as FaIcons from 'react-icons/fa';
import moment from 'moment';
import LargeSpinner from '../components/LargeSpinner';
import { domainHook } from '../hooks/domain';
import { useUser } from '../hooks/useUser';
import { Button } from 'react-bootstrap';
import SpinnerLoader from '../components/SpinnerLoader'

export interface ChangeLog {
    [key: string]: string;
    userID: string;
    changeDate: string;
    changeItem: string;
    changeText: string;
}

const Admin: React.FunctionComponent = () => {
    const {userObject} = useUser();
    const [threatCartCount, setThreatCartCount] = useState(0);
    const [cisaCount, setCisaCount] = useState(0);
    const [threatCartApiDone, setThreatCartApiDone] = useState(false);
    const [cisaApiDone, setCisaApiDone] = useState(false);
    const [loadingCisaCheck, setLoadingCisaCheck] = useState(false);
    const [loadingThreatCheck, setLoadingThreatCheck] = useState(false);

    let domain = domainHook();

    const runCheck = async() => {

        setLoadingCisaCheck(true);
        setLoadingThreatCheck(true);

        fetch(`${domain}/Vulnerabilities/GetAllCves` ,{method: 'GET',})
        .then((response) => response.json())
        .then((data) => {
            setThreatCartCount(data.length);
        }).catch((err) => {
            console.log(err);
        })
        .finally(() => {
            setThreatCartApiDone(true);
            setLoadingThreatCheck(false);
        });


        fetch(`https://services.nvd.nist.gov/rest/json/cves/2.0?hasKev` ,{method: 'GET'})
        .then((response) => response.json())
        .then((data) => {
            setCisaCount(data.vulnerabilities.length);
        }).catch((err) => {
            console.log(err);
        })
        .finally(() => {
            setCisaApiDone(true);
            setLoadingCisaCheck(false);
        });

    }

    return (
        <>
            <div id="vendors" className="tabcontent">
                <h3>Admin</h3>

                <div className="dashboard-container" style={{display: "flex", justifyContent: "center"}}>
                    <div style={{width: "75%", height: "300px", border: "3px solid gray", marginTop: "100px", padding: "30px", borderRadius: "30px"}}>
                        <div>Check the CISA Known Exploited Vulnerabilities Catalog</div>
                        <div style={{marginTop: "20px"}}>
                            <Button variant="outline-info" onClick={runCheck}>Run Check</Button>
                        </div>

                        {(loadingCisaCheck && loadingThreatCheck)  ? <SpinnerLoader /> : ""}
                        {(threatCartApiDone && cisaApiDone) && 
                            <div style={{marginTop: "30px", width: "100%"}}>
                                <u><h5 style={{color: "green"}}>Check Complete</h5></u>
                                <div style={{width: "100%", float: "left"}}>
                                    <div style={{float: "left", width: "250px"}}>Cisa CVE Records: </div>
                                    <div style={{fontWeight: "bold"}}> {cisaCount} Records</div>
                                </div>
                                <div style={{width: "100%"}}>
                                    <div style={{float: "left" , width: "250px"}}>Threat Cart CVE Records: </div>
                                    <div style={{fontWeight: "bold"}}> {threatCartCount} Records</div>
                                </div>
                                {cisaCount > threatCartCount ?
                                <>
                                    <div style={{color: "red", marginTop: "15px", fontWeight: "bold", float: "left", lineHeight: "15px"}}>{cisaCount - threatCartCount} Cisa updates available for Threat Cart</div>
                                    <Button style={{lineHeight: "30px"}} variant="link">Update Now</Button>
                                </>
                                :
                                <div style={{color: "green", marginTop: "15px", fontWeight: "bold"}}>Threat Cart records are up to date with CISA</div>
                                }
                            </div>
                        }
                        
                    </div>

    
                </div>
            </div>
        </>
    )
}

export default Admin
